<template>
  <v-card flat>
    <template>
      <v-layout class="my-auto px-4">
        <barcode-scanner v-if="payload.showScanCode" :module="payload.module" v-model="search" :search="search" :scannerPayload="scannerPayload" :disabled="!checkActionPermission(payload.module, CONSTANTS.EDIT)" :loading="scannerFieldLoading"></barcode-scanner>
        <v-text-field v-else solo :label="$t('message.common.search')" class="pa-0" v-model="search" hide-details dense></v-text-field>
      </v-layout>
      <v-card-title class="pa-0 pl-1 mt-1">
        <v-btn color="primary" v-if="payload.addBtnTo && checkActionPermission(payload.module, CONSTANTS.CREATE)" fab x-small id="newbtnTo" :to="payload.addBtnTo" class="ml-3">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <h4>{{ payload.isCustomModules ? payload.moduleTitle : $t(payload.moduleTitle) }}</h4>
        <v-spacer></v-spacer>
        <h6 class="mr-4">{{payload.list.length}} {{ $t('message.common.of') }} {{payload.totalCount}}</h6>
      </v-card-title>
    </template>
    <v-card-text class="mt-2 mb-4">
      <v-card outlined color="#e8eef0" class="pa-0 mt-0" v-if="payload.itemHeaders && payload.list && payload.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in payload.itemHeaders">
            <v-col :cols="payload.items[index].cols" :class="payload.items[index].class" :key="index">
              <strong v-if="header === 'message.projects.drawingGroup' && payload.advanceDrawingTypes">{{$t(header)}}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{$t(header)}}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll
        v-show="payload.list.length"
        :items="payload.list"
        :item-height="getItemHeight(id)"
        max-height="95vh"
        :id="id"
      >
        <template v-slot:default="{ item }" v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card :min-height="$vuetify.breakpoint.xs ? '100' : '55'" :max-height="$vuetify.breakpoint.xs ? '150' : '110'" style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="checkActionPermission(payload.module, CONSTANTS.EDIT) ? payload.editHandler(item) : ''">  <!--:color="$t(payload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? '#f19e05' : '#e8eaf6'" -->
            <v-card-text class="pa-1" :class="$t(payload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in payload.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height" :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block': ''">
                          {{ item[colValues.value] || '-' }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{ item[colValues.value] || '-' }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2" v-if="payload.moduleTitle === 'message.layout.drawing'" dense hide-details @click.stop="" @change="saveStatusUpdate(payload.list.indexOf(item))" v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items" label="Status" outlined ></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!payload.itemHeaders"><strong>{{ $t('message.projects.status') }}:</strong> </span><br v-if="!payload.itemHeaders" />
                                  <span :class="subitems.spanClass">{{item[subitems.value] === 1 ? $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                                </template>
                                </template>
                                <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="payload.actionSize" :cols="payload.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon v-if="!payload.isChecklist && payload.hasDelete && checkActionPermission(payload.module, CONSTANTS.DELETE)" @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2 my-12 overflow-x-hidden" :min-height="id === 'chemicallistScrollList' ? '120' : (id === 'deviationScrollList' ? '160' : '90')" color="#e8eaf6" @click="checkActionPermission(payload.module, CONSTANTS.EDIT) ? payload.editHandler(item) : ''">
            <v-card-text class="pa-0 px-2 my-2">
              <template v-for="(colValues, index) in payload.items">
                <v-row :key="index" class="pt-1 mt-0 align-center">
                  <v-col :cols="12" class="pa-0"  v-if="payload.itemHeaders && payload.itemHeaders.length">
                    <strong v-if="payload.itemHeaders[index] === 'message.projects.drawingGroup' && payload.advanceDrawingTypes" >{{$t(payload.itemHeaders[index])}}</strong>
                    <strong v-else-if="payload.itemHeaders[index] !== 'message.projects.drawingGroup'">{{ $t(payload.itemHeaders[index]) }}</strong>
                  </v-col>
                  <v-col :cols="12" class="pa-0" :class="$t(payload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value] || "-" }} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2" ><strong v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{ item[colValues.value] || "-" }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2" v-if="payload.moduleTitle === 'message.layout.drawing'" dense hide-details @click.stop="" @change="saveStatusUpdate(payload.list.indexOf(item))" v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items" label="Status" outlined ></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass" v-if="!payload.itemHeaders"><strong>{{ $t('message.projects.status') }}:</strong> </span><br v-if="!payload.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ? $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon v-if="!payload.isChecklist && payload.hasDelete && checkActionPermission(payload.module, CONSTANTS.DELETE)" @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          v-if="payload.loading"
          height="6"
        ></v-progress-linear>
        <v-progress-circular
          indeterminate
          color="primary"
          class="mx-auto"
          v-if="false"
        ></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!payload.loading && (payload.list.length === 0)" type="info" class="mt-2" >
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import PermissionsMixin from '@/mixins/permissions'
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
export default {
  mixins: [PermissionsMixin],
  props: ['payload', 'id'],
  data () {
    return {
      search: '',
      debouncedSearch: null,
      bottom: false,
      scannerPayload: {
        placeholder: 'message.mixed.scanSerialNumber',
        change: (this.payload.module === 'equipment' || this.payload.module === 'projectequipment') ? this.getRespectiveEquipment : this.getRespectiveChemical,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      scannerFieldLoading: false
    }
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId']),
    paginationInfo () {
      return this.recordsInfo
    }
  },
  mounted () {
    // search after some ms
    this.debouncedSearch = this.$formatter.debounce(() => {
      this.$eventBus.$emit('loadList', this.search)
    }, 750)

    // check if id loaded then do the below
    const el = document.getElementById(this.id)
    if (el && !this.payload.listenerAdded) {
      el.addEventListener('scroll', () => {
        this.bottom = this.bottomVisible()
      })
      this.payload.listenerAdded = true
    }
  },
  methods: {
    saveStatusUpdate (index) {
      const moduleId = this.payload.list[index].id
      const header = { 'Content-Type': 'multipart/form-data' }
      const formData = new FormData()
      const url = `${this.getHostRefApi}project_drawing/${moduleId}/drawing_status`
      formData.append('is_completed', this.payload.list[index].is_completed)
      formData.append('id', moduleId)
      formData.append('project_id', this.$route.params.project_id)
      hostAppApi.post(url, formData, { headers: header })
        .then((response) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: response.data.message })
        })
    },
    deleteRecord (id) {
      this.$eventBus.$emit('deleteItems', { module: this.payload.module, id })
    },
    bottomVisible () {
      const el = document.getElementById(this.id)
      const scrollY = el.scrollTop
      const visible = el.clientHeight
      const pageHeight = el.scrollHeight
      const bottomOfPage = visible + scrollY + 1 >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    /**
     * safePush - Navigates to a new route only if it's different from the current one.
     * Prevents Vue Router's NavigationDuplicated error.
    */
    safePush (path) {
      if (this.$route.path !== path) {
        this.$router.push(path).catch(err => {
          if (err.name !== 'NavigationDuplicated') throw err
        })
      }
    },
    /* For equipment list scan */
    getRespectiveEquipment () {
      if (this.search) {
        this.scannerFieldLoading = true
        hostAppApi.get(`${this.getHostRefApi}equipment/get_by_barcode/${this.search}`)
          .then((response) => {
            if (response && response.data && typeof response.data === 'number') {
              this.safePush(`/equipments/${response.data}`)
            } else {
              this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.mixed.noEquipmentFound' })
            }
          })
          .finally(() => { this.scannerFieldLoading = false })
      }
    },
    getRespectiveChemical () {
      if (this.search) {
        this.scannerFieldLoading = true
        hostAppApi.get(`${this.getHostRefApi}chemicallist/get_by_barcode/${this.search}`)
          .then((response) => {
            if (response && response.data && typeof response.data === 'number') {
              this.safePush(`/chemicallist/${response.data}`)
            } else {
              if (this.payload.module === 'chemicallist') this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.mixed.noChemicalFound' })
              else this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.common.noResults' })
            }
          })
          .finally(() => { this.scannerFieldLoading = false })
      }
    },
    getItemHeight (id) {
      if (this.$vuetify.breakpoint.xs) {
        if (id === 'chemicallistScrollList') {
          return '135'
        } else if (id === 'deviationScrollList') {
          return '170'
        } else if (id === 'weldLogScrollList') {
          return '270'
        } else if (id === 'equipmentScrollList') {
          return '185'
        } else {
          return '100'
        }
      } else {
        return this.payload.width
      }
    }
  },
  watch: {
    search (val) {
      if (!this.payload.showScanCode) this.debouncedSearch()
    },
    bottom (val) {
      if (val) {
        if (this.payload.list.length !== this.payload.totalCount) this.$eventBus.$emit('loadList', this.search)
      }
    }
  },
  beforeDestroy () {}
}
</script>
<style scoped>
  .custom-height {
    position: relative;
    width: 100%;
    max-height: 5.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
  }
</style>
